<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <div class="alert alert-danger text-center g">
                سيتم حذف تقارير المعلمين المرفوعة والمسجلة اليوم بتاريخ {{ date }}.<br>ولا يمكن استرجاع التقارير المحذوفة بعد حذفها.
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-outline-danger" @click="deleteNow()">
                    <i class="fa fa-trash"></i>
                    حذف تقارير اليوم
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
    data(){
        return {
            date: new Date().toISOString().split("T")[0],
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    methods: {
        deleteNow(){
            var g = this;
            if(confirm('متأكد من حذف تقارير اليوم نهائيا؟')){
                axios
                .post(api + "/user/teachers/reports/delete-today-report", {
                    jwt: g.user.jwt,
                })
                .then(function(){
                    alert("تم الحذف بنجاح", 100)
                })
            }
        }
    }
}
</script>

<style>

</style>